<template>
  <div>
    <general-table
      ref="cashCallWalletTable"
      :api-url="apiUrl"
      :columns="fields"
      :view-content="false"
      type="page"
      :edit-content="false"
      :delete-content="false"
      :guard="guard"
      :search-box="false"
      :selectable="false"
      :export-table="false"
    >

      <template #cell(id)="data">
        #{{ data.item.id }}
      </template>

      <!-- <template #cell(cashcall_request)="data">
        <ul>
          <li>
            Service code:{{ data.item.cashcall_request.service_code }}
          </li>
          <li>
            Service provider code:{{ data.item.cashcall_request.service_provider_code }}
          </li>
          <li>
            Transaction value:{{ data.item.cashcall_request.transaction_value }}
          </li>
          <li>
            User Account Id:{{ data.item.cashcall_request.user_b_account_id }}
          </li>
        </ul>
      </template>
      <template #cell(cashcall_response)="data">
        <ul>
          <li>
            gle_txn_id:{{ data.item.cashcall_response.gle_txn_id }}
          </li>
          <li>
            User Msg:{{ data.item.cashcall_response.user_a_msg }}
          </li>
          <li>
            Status:{{ data.item.cashcall_response.status_code }}
          </li>
          <li>
            int_sessionless_message:{{ data.item.cashcall_response.int_sessionless_message }}
          </li>
          <li>
            out_parameters:{{ data.item.cashcall_response.out_parameters }}
          </li>
          <li>
            sys_to_sys_msg:{{ data.item.cashcall_response.sys_to_sys_msg }}
          </li>
          <li>
            financial_parameters:{{ data.item.cashcall_response.financial_parameters }}
          </li>
        </ul>
      </template> -->
    </general-table>

  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
// import AdminDashboardTable from '@/views/dashboard/admin-dashboard/components/AdminDashboardTable.vue'
import timeAgo from '@/global-compositions/timeAgo'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  setup() {
    const report = ref([])
    const { getValidationState } = formValidation()
    const guard = 'customer_users'

    const amount = ref('')
    const addBalanceAmount = ref('')
    const loaderEditBalance = false
    const loaderAddBalance = false

    const fields = [
      { key: 'id', label: '#', sortable: true },
      { key: 'user_name', label: 'User' },
      { key: 'phone', label: 'phone' },
      { key: 'amount', label: 'Amount' },
      { key: 'cashcall_request', label: 'Cashcall Request' },
      { key: 'cashcall_response', label: 'Cashcall Response' },

    ]
    const errorMsg = ref('')

    return {
      amount,
      guard,
      loaderEditBalance,
      loaderAddBalance,
      addBalanceAmount,
      getValidationState,
      timeAgo,
      errorMsg,
      fields,
      report,
    }
  },
  computed: {
    apiUrl() {
      const base = 'cashacall/transaction/failed'

      return base
    },

  },
  methods: {

  },
}
</script>

<style>

</style>
